<template>
    <div class="header_weather">
        <div v-show="screenWidth < maxScreenWidth">
            <div class="flex_row">
                <div
                    class="flex_column"
                    style="margin-right: 9px"
                >
                    <div class="weather_week">
                        {{ week }}
                    </div>
                    <div class="weather_temp">
                        {{ nighttemp }}~{{ daytemp }}°C
                    </div>
                </div>
                <div class="weather_windy">{{ dayWindy }}风</div>
            </div>
        </div>
        <div
            v-show="screenWidth >= maxScreenWidth"
            class="flex_row"
        >
            <div class="flex_column">
                <div class="weather_week">
                    {{ week }}
                </div>
                <div class="weather_date">
                    {{ date }}
                </div>
            </div>
            <div class="sparate"></div>
            <div class="flex_column">
                <div class="weather_address">
                    {{ address }}
                </div>
                <div class="weather">
                    {{ dayweather }}
                </div>
            </div>

            <div class="flex_column">
                <div class="weather_temp">{{ nighttemp }}~{{ daytemp }}°C</div>
                <div class="weather_windy">{{ dayWindy }}风</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "HeatherWeather",
    props: {
        screenWidth: {
            type: Number,
        },
        maxScreenWidth: {
            type: Number,
        },
    },
    data() {
        return {
            week: "周二",
            date: "08/16",
            address: "石家庄",
            dayweather: "晴",
            daytemp: "33",
            nighttemp: "24",
            dayWindy: "南",
        };
    },
    mounted() {
        this.getWeather();
    },
    methods: {
        /**
         * @Description: 获取天气
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-14 15:00:41
         */
        getWeather() {
            var xhr = new XMLHttpRequest();
            xhr.open("get", `https://query.asilu.com/weather/gaode`);
            xhr.send(null);
            xhr.onreadystatechange = () => {
                var res = JSON.parse(xhr.response);
                let {forecasts} = res;
                let {city, reporttime, casts} = forecasts[0];
                let {
                    daytemp,
                    dayweather,
                    daywind,
                    nighttemp,
                    nightweather,
                    nightwind,
                    week,
                } = casts[0];
                let arr = ["一", "二", "三", "四", "五", "六", "日"];

                this.week = "周" + arr[Number(week) - 1];
                this.date = reporttime.slice(5, 10);
                this.address = city;
                // 获取当前时间
                let now = new Date();
                // 获取当前小时数
                let hour = now.getHours();
                // 判断当前时间是早上、中午还是下午、晚上
                if (hour >= 5 && hour < 18) {
                    this.dayweather = dayweather;
                    this.dayWindy = daywind;
                } else {
                    this.dayweather = nightweather;
                    this.dayWindy = nightwind;
                }
                this.daytemp = daytemp;
                this.nighttemp = nighttemp;
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.header_weather {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    // flex: 50px 0 1;

    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .sparate {
        width: 1px;
        height: 22px;
        border-left: 1px dashed #ffffff;
        margin: 0 6px;
    }
    .weather_address {
        margin-right: 11px;
    }
    .weather {
        margin-right: 11px;
    }
    .weather_windy {
        flex-shrink: 0;
    }
}
.flex_column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
}
.flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.flex1 {
    display: flex;
    flex-direction: row;
}
</style>
