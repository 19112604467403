<template>
    <div class="user-info">
        <div class="global-search-wrapper">
            <!-- <img class="internet-search" :src="internetSearch" alt="internet-search"> -->
            <span class="iconfont icon-sousuo internet-search"></span>
            <input
                ref="headSearch"
                class="global-search"
                id="J_global_search"
                v-model="globalSearch"
                placeholder="搜索功能"
                @input="handlerInput"
                @focus="handlerFocus"
                @blur="handlerBlur"
                type="text"
                autocomplete="off"
            />
        </div>

        <div class="notification-wrapper" v-if="hasPermission(['message:notifications'])">
            <el-badge
                :hidden="newsNum === 0"
                :value="newsNum"
                :max="99"
                @click.native="gotoNotificationList"
            >
                <span class="iconfont icon-tongzhi"></span>
            </el-badge>

        </div>

        <div class="right-menu">
            <el-dropdown
                class="avatar-container"
                trigger="click"
            >
                <div class="avatar-wrapper">
                    <el-image
                        :src="userImg ? decodeURI(userImg) : imgErrorAdr"
                        class="user-avatar"
                        alt="user-img"
                    ></el-image>
                    <div class="user-name">
                        {{ $store.state.userName || "admin" }}
                    </div>
                    <i class="el-icon-arrow-down user-down" />
                </div>
                <el-dropdown-menu
                    slot="dropdown"
                    class="user-dropdown"
                >
                    <el-dropdown-item v-if="hasThemeChange" @click.native="changeTheme">
                        <span class="operate-item">更换主题</span>
                    </el-dropdown-item>
                    <div v-if="hasThemeChange" style="border-top: 1px dashed #DCDEE0; box-sizing: border-box;margin: 5px 10px 0px;"></div>
                    <el-dropdown-item v-if="userRouters.find(e => e.name === 'SetCenter')" @click.native="setCenter">
                        <span class="operate-item">设置中心</span>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="userProfile">
                        <span class="operate-item">个人档案</span>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="changePassword">
                        <span class="operate-item">个人中心</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="isHelp" @click.native="goHelp">
                        <span class="operate-item">帮助中心</span>
                    </el-dropdown-item>
                    <div style="border-top: 1px dashed #DCDEE0; box-sizing: border-box;margin: 5px 10px 0px;"></div>
                    <el-dropdown-item @click.native="layout">
                        <span class="operate-item">退出登录</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import {debounce} from "common-local";
import {mapState} from "vuex";
import tipContent from "@/assets/data/tipContent.js";
import HeadSearchRes from "./HeadSearchRes/index.vue";
import {createdSearchRes} from "./HeadSearchRes/createdSearchRes.js";
import {getToken} from "@/libs/auth";
import { hasPermission} from "@/libs/utils.js";

export default {
    name: "HeaderUser",
    components: {
        HeadSearchRes
    },
    data() {
        return {
            hasPermission,
            tipContent,
            globalSearch: "",
            resList: [],
            globalSearch: "",
            placement: {
                top: "",
                left: "",
            },
            headSearchRes: null,
        };
    },
    computed: {
        ...mapState({
            dealtNum: (state) => state.dealtNum,
            userImg: (state) => state.userImg,
            originRouters: (state) => state.originRouters,
            userRouters: (state) => state.userRouters,
            schoolName: (state) => state.schoolName,
            userName: (state) => state.userName,
            newsNum: (state) => state.newsNum,
            permission: state => state.permission
        }),
        bellUrl() {
            return require("@/assets/images/bell.png");
        },
        documentationUrl() {
            return require("@/assets/images/documentation.png");
        },
        avatarUrl() {
            return require("@/assets/images/avatar.png");
        },
        AIImg() {
            return require("@/assets/images/AI.png");
        },
        logout() {
            return require("@/assets/images/logout.png");
        },
        internetSearch () {
            return require("@/assets/images/internet-search.png");
        },
        imgErrorAdr() {
            return require('@/assets/images/imgErrorAdr.png')
        },
        notification() {
            return require('@/assets/images/notification.png')
        },
        isHelp(){
            let show  = false;
            if(this.originRouters && this.originRouters.length > 0){  
                show = this.originRouters.some(item => item.name === "systemHelp");
            }
            return show
        },
        hasThemeChange() {
            const USER_PERMISSION = 'themeSkin';
            let show = false;
            if(this.permission.userPermission && this.permission.userPermission.length > 0){  
                show = this.permission.userPermission.some(item =>  USER_PERMISSION === item);
            }
            return show
        }
    },
    methods: {
        userInfo() {
            this.$router.push({path: "/user/userInfo"});
        },
        userProfile() {
            this.$router.push({path: "/user/userProfile"});
        },
        changeTheme() {
            this.$eventDispatch('changeTheme',{open: true})
            // this.$router.push({path: "/themeColor"});
        },
        changePassword() {
            this.$router.push({path: "/user/changePassword"});
        },
         /**
         * @Description: 跳转帮助中心
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 周浩
         * @Date: 2024-07-05 17:22:32
         */ 
        goHelp() {
            console.debug(this.originRouters,"ooooo");
            this.$router.push({name: "systemHelp"});
        },
        dealtClick() {
            this.$router.push({path: "/dealtList"});
        },
        // 跳转到设置中心
        setCenter() {
            let routeData = this.$router.resolve({
                // name: "SetCenter",
                name: "LoginPageManagement",
                query: {}
            })
            window.open(routeData.href, '_blank')
        },
        layout() {
            localStorage.globalSearchHistory = ""; // 清空菜单搜索历史记录
            this.$store.commit("closeAllTabsMu"); // 清空面包屑数据
            this.$eventDispatch("layout");
        },
        goToAI() {
            let res = `https://ask.xinkaoyun.com:8888/#/login?token=${getToken()}`;
            const _window = window.open();
            if (_window) {
                _window.location.href = res;
            } else {
                const a = document.createElement("a");
                a.href = res;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            console.log(res, "link");
        },
        handlerInput: debounce(function () {
            let resList = [];
            if (this.globalSearch.length > 0) {
                resList = this.originRouters.filter((item) => {
                    return item.title.includes(this.globalSearch);
                });
            } else {
                this.resList = [];
            }

            if (this.globalSearch.length > 0) {
                this.resList = this.findHasChildren(resList, this.userRouters);
            }

            this.createdSearch();
        }, 100),
        findHasChildren(resList, userRouters) {
            resList.forEach((item) => {
                userRouters.forEach((subItem) => {
                    if (item.menuId === subItem.menuId) {
                        item["children"] = subItem.children;
                    }

                    if (subItem.children && subItem.children.length > 0) {
                        this.findHasChildren(resList, subItem.children);
                    }
                });
            });

            return resList;
        },
        handlerFocus() {
            let _this = this;
            this.$nextTick(() => {
                setTimeout(() => {
                    _this.getDownListAdr();
                }, 200)
            })
        },
        handlerBlur() {
            this.$nextTick(() => {
                this.closeHeadSearchRes();
                this.globalSearch = '';
            })
        },
        /**
         * @Description: 获取下拉列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-11 17:31:18
         */
        getDownListAdr() {
            const oGlobalSearch = document.querySelector("#J_global_search");
            let top = this.offsetTop(oGlobalSearch) + 40; //UI 设计间距是40
            let offsetLeft = this.offsetLeft(oGlobalSearch);

            this.placement.top = top + "px";
            this.placement.left = offsetLeft + "px";
            this.createdSearch();
        },
        offsetTop(elements) {
            let top = elements.offsetTop;
            let parent = elements.offsetParent;
            while (parent != null) {
                top += parent.offsetTop;
                parent = parent.offsetParent;
            }
            return top;
        },
        offsetLeft(elements) {
            let left = elements.offsetLeft;
            let parent = elements.offsetParent;
            while (parent != null) {
                left += parent.offsetLeft;
                parent = parent.offsetParent;
            }

            return left;
        },
        createdSearch() {
            this.headSearchRes && this.headSearchRes.close();

            this.headSearchRes = createdSearchRes(HeadSearchRes, {
                globalSearch: this.globalSearch,
                placement: this.placement,
                resList: this.resList,
                userRouters: this.userRouters,
                originRouters: this.originRouters,
            });
        },
        closeHeadSearchRes() {
            this.headSearchRes.inputBlur();
        },
        gotoNotificationList() {
            this.$router.push({path: "/notificationList"});
        },
    },
};
</script>

<style lang="scss" scoped>
.user-info {
    min-width: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .notification-wrapper {
        color: #fff;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
        font-size: 25px;

        .icon-tongzhi {
            font-size: 25px;
            color: var(--header-text)
        }

        .el-badge {
            height: 36px;
        }
    }
    .global-search-wrapper {
            color: #fff;
            width: auto;
            border-radius: 18px;
            min-width: 36px;
            height: 32px;
            line-height: 32px;
            display: inline-block;
            position: relative;
            overflow: hidden;
            background-size: 104% 104%;
            cursor: pointer;
            margin-right: 20px;
            margin-top: 4px;
        .icon-sousuo {
            color: var(--header-text);
        }

        .internet-search {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 28px;
            text-align: center;
            cursor: pointer;
            z-index: 5;
        }
        .global-search {
            display: inline-block;
            background: 0 0;
            border-radius: 18px;
            border: none;
            padding-left: 12px;
            line-height: 31px !important;
            height: 31px;
            box-sizing: border-box;
            vertical-align: 4px;
            font-size: 14px;
            width: 36px;
            color: #606266;
            transition: all .3s ease-in-out;
        }
        input {
            padding-right: 40px;
        }
        input:focus{
            display: inline-block;
            width: 140px;
            background: #FFFFFF;
            outline: none;
        }
    }
    .global-search-wrapper:hover {
        .global-search {
            display: inline-block;
            width: 140px;
            background: #FFFFFF;
        }
        .internet-search {
            color: #FFFFFF;
        }
    }

    .right-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
        flex-shrink: 0;

        &:focus {
            outline: none;
        }
        .ai-work {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            img {
                width: 30px;
                display: block;
            }
            span {
                display: block;
                margin-top: 8px;
                font-size: 12px;
                line-height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #cee0f2;
                transform: scale(0.9);
            }
        }
        .image-wrapper {
            box-sizing: border-box;
            padding-top: 3px;
            padding-right: 23px;
            margin-right: 23px;
            cursor: pointer;
            border-right: 1px solid #bacbdb;
            position: relative;
            .imgTxt {
                width: 66px;
                position: absolute;
                top: 25px;
                left: -15px;
                color: rgb(255, 255, 255);
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                display: block;
                margin-top: 7px;
                font-size: 12px;
                line-height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #cee0f2;
                transform: scale(0.9);
            }
        }

        .right-menu-item {
            display: inline-block;
            color: #5a5e66;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            cursor: pointer;

            .avatar-wrapper {
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .user-name {
                    width: 70px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: var(--header-text);
                    margin-right: 10px;
                }
                .user-down {
                    color: var(--header-text);
                }
            }
        }
    }
}

.operate-item {
    display: block;
    width: 60px;
    text-align: center;
}

.el-icon-switch-button {
    cursor: pointer;
    position: relative;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
}

.bell-wrap {
    margin-left: 30px;

    position: relative;
    .documentationTxt {
        position: absolute;
        width: 66px;
        top: 22px;
        left: -18px;
        color: rgb(255, 255, 255);
    }

    .my-to-do-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            display: block;
            margin-top: 8px;
            font-size: 12px;
            line-height: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #cee0f2;
            transform: scale(0.9);
        }
    }
}
.logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 3px;
    cursor: pointer;
    img {
        width: 20px;
        display: block;
    }
    span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        line-height: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cee0f2;
        transform: scale(0.9);
    }
}

.badge-item {
    margin-top: 5px;
    margin-right: 27px;
    cursor: pointer;
    .bell-img {
        width: 20px;
        height: 22px;
    }
    .documentation-img {
        width: 23px;
        height: 23px;
    }
}

/deep/ .el-badge__content {
    border: 0;
}
</style>
